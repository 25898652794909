/* App.css */

@tailwind base;
@tailwind components;
@tailwind utilities;


:root{
  --primary-color: #3EA842;
  --secondary-color: #1B4B1D;
  --support-text-color: #333333;
  --bg-color: #F8FCF8;
  --card-bg: #D3DCD1;
}
body{
  color: var(--text-color);
}
iframe{
  width: 100% !important;
}
/* Initial hidden state */
.hidden {
  opacity: 0;
  transform: translateY(20px); /* Adjust as needed */
}

.responseInfo{
  /* background-color: red; */
}
.responseInfo img{
  width: 100%;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 1;
  animation: fadeIn 2s ease-in-out forwards;
}

/* Slide-in from left animation */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  transform: translateX(0);
  opacity: 1;
  animation: slideInLeft 2s ease-in-out forwards;
}

/* Slide-in from right animation */
@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.slide-in {
  transform: translateX(0) !important;
  opacity: 1 !important;
}
.slide-in-right {
  transform: translateX(0);
  opacity: 1;
  animation: slideInRight 2s ease-in-out forwards;
}

/* Slide-in from top animation */
@keyframes slideInTop {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-top {
  transform: translateY(0);
  opacity: 1;
  animation: slideInTop 2s ease-in-out forwards;
}

/* Slide-in from bottom animation */
@keyframes slideInBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-in-bottom {
  transform: translateY(0);
  opacity: 1;
  animation: slideInBottom 1.5s ease-in-out forwards;
}

.landing_bg{
  background-image: url(../public/assets/images/landing/hero_bg.jpg);
  width: 100%;
  height: 90vh;
  background-size: cover;
}
.section2bg{
  background-image: url(../public/assets/about/img1.png);
}
